const typeDescriptions = {
  "BLACKFORCE PROFESSIONAL ONE-TIME UPGRADE - $1,100":
    "Make a one-time payment of $1,100 to complete your professional upgrade.",
  "BLACKFORCE PROFESSIONAL SPLIT-PAYMENT UPGRADE - $550": `Pay in installments of $550. <span class="text-danger"> Deadline for payment balance is 30th April 2024! </span>`
};

const grossAmount = {
  "BLACKFORCE PROFESSIONAL ONE-TIME UPGRADE - $1,100": 1100,
  "BLACKFORCE PROFESSIONAL SPLIT-PAYMENT UPGRADE - $550": 550
};
export const taxAmount = {
  Alberta: 5,
  "British Columbia": 5,
  Manitoba: 5,
  "New Brunswick": 15,
  "Newfoundland and Labrador": 15,
  "Northwest Territories": 5,
  "Nova Scotia": 15,
  Nunavut: 5,
  Ontario: 13,
  Quebec: 5,
  "Prince Edward Island": 15,
  Saskatchewan: 5,
  Yukon: 5
};

const paymentLinks = {
  "BLACKFORCE PROFESSIONAL ONE-TIME UPGRADE - $1,100": {
    5: "https://buy.stripe.com/bIYg0c8oM43l9UIbJC", // https://buy.stripe.com/aEUbJWbAYczR4AoaET
    15: "https://buy.stripe.com/00gcO0gVibvN7MA6pj", // https://buy.stripe.com/aEU7tG48wdDV4AoaEU
    13: "https://buy.stripe.com/cN2eW834sgQ70k8150" // https://buy.stripe.com/9AQdS46gEbvNeaY5kw
  },
  "BLACKFORCE PROFESSIONAL SPLIT-PAYMENT UPGRADE - $550": {
    5: "https://buy.stripe.com/00g7tGeNaeHZd6U151", // https://buy.stripe.com/00g5ly48wdDVgj628p
    15: "https://buy.stripe.com/5kAcO0dJ6eHZ2sg8xu", // https://buy.stripe.com/fZe6pC8oMfM3c2QdR8
    13: "https://buy.stripe.com/14k29mawU1Vd3wkfZX" // https://buy.stripe.com/aEUbJW5cA9nFd6U5kD
  }
};

export function getPaymentLink(type, province) {
  let percentage = taxAmount[province];
  return paymentLinks[type][percentage];
}
export function getTypeDescription(type) {
  if (type) {
    return typeDescriptions[type];
  } else {
    return "";
  }
}

export function getAmountFigure(key, type, province, gross) {
  if (key === "gross") {
    return grossAmount[type];
  } else if (key === "tax") {
    if (type === "YES I AM BASED IN CANADA" && province !== "") {
      return (taxAmount[province] / 100) * grossAmount[gross];
    } else {
      return 0;
    }
  } else if (key === "total") {
    if (type === "YES I AM BASED IN CANADA" && province !== "") {
      return (
        (taxAmount[province] / 100) * grossAmount[gross] + grossAmount[gross]
      );
    } else {
      return grossAmount[gross];
    }
    // return (taxAmount[province] || 0 / 100) * grossAmount[gross] + grossAmount[type];
  } else {
    return 0;
  }
}
