import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { useSelector, shallowEqual } from "react-redux";

import { DashboardPage } from "../app/pages/DashboardPage";
const DashboardPage1 = lazy(() =>
  import("../app/modules/UserProfile/UserProfilePage")
);
// const MeetingSettingPage = lazy(() =>
// 	import('./modules/MeetingSettings/MeetingSettings'),
// )
// const SettingPage = lazy(() => import('./modules/Setting/SettingEditPage'))
const CertificatesPage = lazy(() =>
  import("./modules/Certificates/certificatePage")
);
const AssignmentsPage = lazy(() =>
  import("./modules/Assignments/certificatePage")
);
const ProfilesPage = lazy(() => import("./modules/Profiles/profilePage"));
const GroupsPage = lazy(() => import("./modules/Groups/groupPage"));
const TemplatesPage = lazy(() => import("./modules/Templates/templatesPage"));

export default function BasePage() {
  const { role_level } = useSelector(
    state => state.auth.user.role_details,
    shallowEqual
  );
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/certificates" component={CertificatesPage} />
        <Route path="/assignments" component={AssignmentsPage} />
        <Route path="/study-groups" component={GroupsPage} />
        {role_level <= 4 && (
          <>
            <Route path="/v/profiles/:id" component={DashboardPage1} />
            <Route path="/profiles" component={ProfilesPage} />
            <Route path="/templates" component={TemplatesPage} />
          </>
        )}
        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
}
