import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { settingsSlice } from "../app/modules/Setting/_redux/settings/settingsSlice";
import { profileSlice } from "../_metronic/_partials/dashboards/_redux/ProfileSlice";
import { profilesSlice } from "../app/modules/Profiles/_redux/profileSlice";
import { certificatesSlice } from "../app/modules/Certificates/_redux/certificateSlice";
import { assignmentsSlice } from "../app/modules/Assignments/_redux/assignmentSlice";
import { groupsSlice } from "../app/modules/Groups/_redux/groupSlice";
import { templateSlice } from "../app/modules/Templates/_redux/templateSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  settings: settingsSlice.reducer,
  profile: profileSlice.reducer,
  profiles: profilesSlice.reducer,
  certificate: certificatesSlice.reducer,
  assignment: assignmentsSlice.reducer,
  group: groupsSlice.reducer,
  template: templateSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
