import axios from "axios";

export const PROFILE_URL = process.env.REACT_APP_API_URL + "/profiles";

// READ
export function getProfile(id) {
  return axios.get(`${PROFILE_URL}/${id}`);
}

// UPDATE => PUT: update the procuct on the server
export function createProfile(profile) {
  return axios.post(`${PROFILE_URL}`, { profile });
}
export function generateReferenceLetter(id) {
  return axios.post(`${PROFILE_URL}/generateRefLetter`, { id });
}
export function generateContractLetter(id) {
  return axios.post(`${PROFILE_URL}/generateConLetter`, { id });
}
