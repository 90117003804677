import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { format } from "date-fns";
import axios from "axios";
// import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
// import { createMultipleLeads } from "../../../_redux/lead/leadActions";

export function UploadCsvDialog({
  setResumeUrl,
  show,
  onHide,
  setDisplayFile
}) {
  const [file, setFile] = useState(undefined);
  const [uploading, setUploading] = useState(false);

  const dispatch = useDispatch();
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    if (acceptedFiles.length === 0) {
      toast.warn("Please upload a valid file with size less than 10mb");
    }
    acceptedFiles.forEach(file => {
      setFile(file);
    });
  }, []);

  // init dropZone
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
        ".docx"
      ]
    },
    maxSize: 1250000,
    maxFiles: 1,
    multiple: false
  });

  function clearFile() {
    setFile(undefined);
  }

  const handleUpload = async () => {
    // onHide(clearFile);
    setUploading(true);
    if (file) {
      // if (file.name.split(".")[1] === "json") {
      // const reader = new FileReader();
      // reader.onload = () => {
      //   // Do whatever you want with the file contents
      //   const binaryStr = reader.result;
      //   dispatch(createMultipleLeads({ leads: JSON.parse(binaryStr) }));
      //   onHide(clearFile);
      //   setUploading(false);
      // };
      // reader.readAsText(file);
      // console.log(JSON.parse(file));
      // } else {
      try {
        let cancelTokenSource = undefined;
        const fileName = format(new Date(), "yyyyMMddHHmmss") + "-" + file.name;
        const fileType = file.type;

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/sign_s3`,
          {
            fileName,
            fileType
          }
        );

        const returnData = response.data.data.returnData;
        var signedRequest = returnData.signedRequest;
        var url = returnData.url;
        cancelTokenSource = axios.CancelToken.source();
        var instance = axios.create();
        delete instance.defaults.headers.common["Authorization"];
        var options = {
          headers: {
            "Content-Type": fileType
          },
          cancelToken: cancelTokenSource.token
        };
        let results = await instance.put(signedRequest, file, options);
        setResumeUrl(url);

        setUploading(false);
        setDisplayFile(file);
        onHide(clearFile);
      } catch (error) {
        //@TODO handle error with state hooks
        console.log(error);
        setUploading(false);
        onHide(clearFile);
        // setUploadError(error);
      }
      // }
    } else {
      setUploading(false);
      onHide(clearFile);
    }
  };

  return (
    <>
      {/* <ToastContainer /> */}
      <Modal
        size="md"
        show={show}
        onHide={() => onHide(clearFile)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Upload resume
          </Modal.Title>
        </Modal.Header>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <div className="form-group row">
              <div className="col-lg-10 col-md-10 col-sm-12 ml-10 mt-10">
                <div
                  className="dropzone dropzone-default dropzone-primary"
                  id="kt_dropzone_3"
                >
                  <div className="dropzone-msg dz-message needsclick">
                    <h3 className="dropzone-msg-title">Drop file here</h3>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {file ? (
                <>
                  {" "}
                  <div className="form-group row">
                    <div className="col-lg-10 col-md-10 col-sm-12 ml-10 mt-10">
                      <div className="dropzone-items">
                        <div className="dropzone-item">
                          <div className="dropzone-file">
                            <div
                              className="dropzone-filename"
                              title="some_image_file_name.jpg"
                            >
                              <span data-dz-name="">{file.path}</span>
                              <strong>
                                (<span data-dz-size="">{`${file.size}kb`}</span>
                                )
                              </strong>
                            </div>
                            <div
                              className="dropzone-error"
                              data-dz-errormessage=""
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="form-group row">
                  <div className="col-lg-10 col-md-10 col-sm-12 ml-10 mt-10">
                    <div
                      className="dropzone dropzone-default dropzone-primary"
                      id="kt_dropzone_3"
                    >
                      <div className="dropzone-msg dz-message needsclick">
                        <h3 className="dropzone-msg-title">
                          Drop file here or click to upload.
                        </h3>
                        <span className="dropzone-msg-desc">
                          Only .pdf &amp; .docx extension is allowed for upload
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => onHide(clearFile)}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            <> </>
            <button
              type="button"
              onClick={handleUpload}
              className="btn btn-primary btn-elevate"
              disabled={!file || uploading}
            >
              {uploading && (
                <span className="spinner spinner-white mr-8"></span>
              )}
              Upload
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
