import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../_helpers";
import SVG from "react-inlinesvg";
import { GenerateReferenceLetterModal } from "./components/GenerateReferenceLetterDialog";
import { GenerateContractLetterModal } from "./components/GenerateContractLetterModal";
export function OverviewCard({ user, profile }) {
  const [pic, setPic] = useState("");
  const [showReferenceModal, setShowReferenceModal] = useState(false);
  const [showContractModal, setShowContractModal] = useState(false);

  useEffect(() => {
    if (user.pic) {
      setPic(user.pic);
    }
  }, [user]);
  const getUserPic = () => {
    if (!pic) {
      return "/media/users/blank.png";
    }

    return pic;
  };

  return (
    <>
      <div className="card card-custom gutter-b">
        <div className="card-body">
          <div className="d-flex">
            {/*begin: Pic*/}
            <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
              <div className="symbol symbol-50 symbol-lg-120">
                <img alt="Profile" src={`${getUserPic()}`} />
              </div>
              <div className="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
                <span className="font-size-h3 symbol-label font-weight-boldest">
                  JM
                </span>
              </div>
            </div>
            {/*end: Pic*/}
            {/*begin: Info*/}
            <div className="flex-grow-1">
              {/*begin: Title*/}
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <div className="mr-3">
                  {/*begin::Name*/}
                  <span className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">
                    {user?.user_detail?.name}
                    <i className="flaticon2-correct text-success icon-md ml-2"></i>
                  </span>
                  {/*end::Name*/}
                  {/*begin::Contacts*/}
                  <div className="d-flex flex-wrap my-2">
                    <span className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Mail-opened.svg"
                            )}
                          ></SVG>{" "}
                        </span>
                      </span>
                      {user?.user_detail?.email}
                    </span>
                    <span className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/User.svg"
                            )}
                          ></SVG>{" "}
                        </span>
                      </span>
                      {user?.department_name}
                    </span>
                    <span className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Bookmark.svg"
                            )}
                          ></SVG>{" "}
                        </span>
                      </span>
                      {user?.profile_name}
                    </span>
                    <span className="text-muted text-hover-primary font-weight-bold">
                      <span className="navi-icon mr-2">
                        <span className="svg-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Design/Layers.svg"
                            )}
                          ></SVG>{" "}
                        </span>
                      </span>
                      {user?.role_details?.role_name}
                    </span>
                  </div>
                  {/*end::Contacts*/}
                </div>
                <div className="mb-10">
                  {profile && (
                    <>
                      {/* {profile?.canGenerateReferenceLetter &&
                        profile?.referenceLetterUrl && (
                          <>
                            <a
                              href={profile?.referenceLetterUrl}
                              rel="noreferrer"
                              target="_blank"
                              className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-2"
                            >
                              download reference letter
                            </a>
                          </>
                        )} */}
                      {profile?.canGenerateReferenceLetter && (
                        <>
                          {" "}
                          <span
                            onClick={() => setShowReferenceModal(true)}
                            className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-2"
                          >
                            generate reference letter
                          </span>{" "}
                        </>
                      )}
                      {profile?.canGenerateContractLetter && (
                        <span
                          onClick={() => setShowContractModal(true)}
                          className="btn btn-sm btn-primary font-weight-bolder text-uppercase mr-2"
                        >
                          generate contract letter
                        </span>
                      )}
                      {/* {profile?.canGenerateContractLetter &&
                        profile?.contractLetterUrl && (
                          <a
                            href={profile?.contractLetterUrl}
                            rel="noreferrer"
                            target="_blank"
                            className="btn btn-sm btn-primary font-weight-bolder text-uppercase mr-2"
                          >
                            download contract letter
                          </a>
                        )} */}
                    </>
                  )}
                </div>
                {/* <div className="my-lg-0 my-1">
                <span className="btn btn-sm btn-info font-weight-bolder text-uppercase">
                  Update
                </span>
              </div> */}
              </div>
              {/*end: Title*/}
              {/*begin: Content*/}
              <div className="d-flex align-items-center flex-wrap justify-content-between">
                <div className="flex-grow-1 font-weight-bold text-dark-50 py-2 py-lg-2 mr-5">
                  The profile review form is in line with our plans to enhance
                  your learning and build your confidence within the Salesforce
                  ecosystem, we will be dividing you into smaller groups based
                  on your profile. Your assigned group will be filled with
                  people of like minds but from different professional
                  backgrounds. The purpose of the grouping would be to encourage
                  collective discussions and brainstorming on Salesforce
                  projects, certification preparation, and work experience
                  discussions amongst other activities.
                </div>
                <div className="d-flex flex-wrap align-items-center py-2">
                  <div className="d-flex align-items-center mr-10"></div>
                </div>
              </div>
              {/*end: Content*/}
            </div>
            {/*end: Info*/}
          </div>
          <div className="separator separator-solid my-7"></div>
          {/*begin: Items*/}
          <div className="d-flex align-items-center flex-wrap">
            {/*begin: Item*/}
            <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
              <span className="mr-4">
                <i className="flaticon-piggy-bank icon-2x text-muted font-weight-bold"></i>
              </span>
              <div className="d-flex flex-column text-dark-75">
                <span className="font-weight-bolder font-size-h5">Cohort</span>
                <span className="font-weight-bolder font-size-sm">
                  {profile?.currentEnrollment?.cohort || "N/A"}
                </span>
              </div>
            </div>
            {/*end: Item*/}
            {/*begin: Item*/}
            <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
              <span className="mr-4">
                <i className="flaticon-confetti icon-2x text-muted font-weight-bold"></i>
              </span>
              <div className="d-flex flex-column text-dark-75">
                <span className="font-weight-bolder font-size-h5">
                  Study Group
                </span>
                <span className="font-weight-bolder font-size-sm">
                  {profile?.Groups?.length
                    ? profile.Groups[profile.Groups.length - 1].name
                    : "N/A"}
                </span>
              </div>
            </div>
            {/*end: Item*/}
            {/*begin: Item*/}
            <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
              <span className="mr-4">
                <i className="flaticon-pie-chart icon-2x text-muted font-weight-bold"></i>
              </span>
              <div className="d-flex flex-column text-dark-75">
                <span className="font-weight-bolder font-size-h5">
                  Subscription Date
                </span>
                <span className="font-weight-bolder font-size-sm">
                  {profile?.currentEnrollment?.created_date
                    ? new Date(
                        profile?.currentEnrollment?.created_date
                      ).toDateString()
                    : ""}
                </span>
              </div>
            </div>
            {/*end: Item*/}
            {/*begin: Item*/}
            <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
              <span className="mr-4">
                <i className="flaticon-file-2 icon-2x text-muted font-weight-bold"></i>
              </span>
              <div className="d-flex flex-column flex-lg-fill">
                <span className="text-dark-75 font-weight-bolder font-size-h5">
                  Subscription Status
                </span>

                <span className="text-success  text-uppercase font-size-sm">
                  {
                    { true: "active", false: "inactive" }[
                      profile?.currentEnrollment?.status
                    ]
                  }
                </span>
              </div>
            </div>
            {/*end: Item*/}
            {/*begin: Item*/}
            {/* <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
            <span className="mr-4">
              <i className="flaticon-chat-1 icon-2x text-muted font-weight-bold"></i>
            </span>
            <div className="d-flex flex-column">
              <span className="text-dark-75 font-weight-bolder font-size-sm">
                648 Comments
              </span>
              <a href="#" className="text-primary font-weight-bolder">
                View
              </a>
            </div>
          </div> */}
            {/*end: Item*/}
          </div>
          {/*begin: Items*/}
        </div>
      </div>
      <GenerateReferenceLetterModal
        show={showReferenceModal}
        onHide={() => setShowReferenceModal(false)}
        id={user?.user_detail.account_id}
        uniqueToken={profile?.uniqueToken}
        uniqueTokenExpiresAt={profile?.uniqueTokenExpiresAt}
        uniqueTokenLastUsed={profile?.uniqueTokenLastUsedReference}
      />
      <GenerateContractLetterModal
        show={showContractModal}
        onHide={() => setShowContractModal(false)}
        id={user?.user_detail.account_id}
        uniqueToken={profile?.uniqueToken}
        uniqueTokenExpiresAt={profile?.uniqueTokenExpiresAt}
        uniqueTokenLastUsed={profile?.uniqueTokenLastUsedContract}
      />
    </>
  );
}
