import * as requestFromServer from "./ProfileCrud";
import { profileSlice, callTypes } from "./ProfileSlice";

const { actions } = profileSlice;

export const getProfile = accountId => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getProfile(accountId)
    .then(response => {
      if (response.data.status) {
        const profile = response.data.data;
        dispatch(
          actions.profileFetched({
            profile
          })
        );
      } else {
        dispatch(
          actions.profileFetched({
            profile: undefined
          })
        );
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find profile";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createProfile = ({ profile, account_id }) => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.createProfile(profile);
    if (response.data.status) {
      dispatch(getProfile(account_id));
      return response;
    } else {
      return response;
    }
  } catch (error) {
    error.clientMessage = "Can't create profile";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};
export const generateReferenceLetter = id => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.reference }));
  try {
    const response = await requestFromServer.generateReferenceLetter(id);
    if (response.data.status) {
      dispatch(actions.referenceFetched());
      // dispatch(getProfile(id));
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    error.clientMessage = "Can't create reference letter";
    dispatch(actions.catchError({ error, callType: callTypes.reference }));
    return error.response ? error.response : error;
  }
};

export const generateContractLetter = id => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.reference }));
  try {
    const response = await requestFromServer.generateContractLetter(id);
    if (response.data.status) {
      dispatch(actions.referenceFetched());
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    error.clientMessage = "Can't create contract letter";
    dispatch(actions.catchError({ error, callType: callTypes.reference }));
    return error.response ? error.response : error;
  }
};
