import { createSlice } from "@reduxjs/toolkit";

const initialProfileState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  profileForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const profilesSlice = createSlice({
  name: "profiles",
  initialState: initialProfileState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getProfileById
    profileFetched: (state, action) => {
      state.actionsLoading = false;
      state.profileForEdit = action.payload.profileForEdit;
      state.error = null;
    },
    // findProfile
    profilesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    dashboardFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.dashboard = action.payload.dashboard;
    },
    // dashboardFetched
    // createProfile
    profileCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.profile);
    },
    // updateProfile
    profileUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.profile.id) {
          return action.payload.profile;
        }
        return entity;
      });
    },
    // deleteProfile
    profileDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(el => el.id !== action.payload.id);
    },
    // deleteProfile
    profilesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // profileUpdateState
    profileStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
