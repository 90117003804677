import React, { useEffect, useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Demo1Dashboard } from "./Demo1Dashboard";

export function Dashboard() {
  const { user } = useSelector(
    ({ auth }) => ({
      user: auth.user
    }),
    shallowEqual
  );

  return <>{user && <Demo1Dashboard user={user} />}</>;
}
