/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../controls";
import * as actions from "../_redux/ProfileActions";
import { toast } from "react-toastify";

export function GenerateReferenceLetterModal({
  id,
  show,
  onHide,
  uniqueToken,
  uniqueTokenExpiresAt,
  uniqueTokenLastUsed
}) {
  const [isValid, setIsValid] = useState(false);
  const [refGenerated, setRefGenerated] = useState(false);
  const [refUrl, setRefUrl] = useState(process.env.REACT_APP_URL);
  // Profile Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    state => ({ isLoading: state.profile.referenceLoading }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const generateLetter = () => {
    // server request for deleting profile by id
    dispatch(actions.generateReferenceLetter(id)).then(resp => {
      if (!resp) {
        toast.error("Something went wrong, please contact an Admin");
        // dispatch(actions.getProfile(id));
      } else {
        // onHide();
        setRefUrl(resp.url);
        setRefGenerated(true);
        toast.success("Reference letter generated successfully");
      }
      // closing delete modal
      // success toast.
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {refGenerated
            ? "Download Reference Letter"
            : "Generate Reference Letter?"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!refGenerated && (
          <>
            {" "}
            <div className="form-group row">
              <div className="col-lg-9 col-xl-9 col-md-9 col-sm-9 col-9">
                <input
                  type="text"
                  id="uniqueToken"
                  placeholder="Enter your personal token"
                  className={`form-control form-control-lg form-control-solid mb-2 `}
                  name="uniqueToken"
                />
              </div>
              <label className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 col-form-label">
                <button
                  type="button"
                  onClick={() => {
                    let field = document.getElementById("uniqueToken").value;
                    if (
                      uniqueToken === field &&
                      uniqueToken !== uniqueTokenLastUsed &&
                      new Date(uniqueTokenExpiresAt) > new Date()
                    ) {
                      setIsValid(true);
                      toast.success("Validated successfully!");
                    } else {
                      setIsValid(false);
                      toast.error(
                        "Token invalid or Expired, Please enter a valid token or contact the training manager"
                      );
                    }
                  }}
                  className="btn btn-light-primary font-weight-bold btn-sm"
                >
                  Validate
                </button>
              </label>
            </div>
            {!isLoading && (
              <span>
                This would generate a reference Letter from Blackforce Inc.
                based on your current Blackforce enrolment.
              </span>
            )}
            {isLoading && <span>Generating reference letter...</span>}
          </>
        )}
        {refGenerated && (
          <>
            <span>Click on the button below to download Reference Letter</span>{" "}
            <br />
            <span className="text-danger">
              NB: You can only use your token once so make sure to download your
              reference letter before closing this modal.
            </span>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          {refGenerated ? (
            <a
              href={refUrl}
              rel="noreferrer"
              target="_blank"
              className="btn btn-sm btn-primary font-weight-bolder text-uppercase mr-2"
            >
              download reference letter
            </a>
          ) : (
            <button
              type="button"
              onClick={generateLetter}
              className="btn btn-delete btn-elevate btn-success"
              disabled={!isValid || isLoading}
            >
              Generate
            </button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}
