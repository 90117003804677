import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import {
  getAmountFigure,
  getPaymentLink,
  getTypeDescription,
  taxAmount
} from "./utils/helpers";
import { Select } from "./utils/Select";

const UpgradeSchema = Yup.object().shape({
  type: Yup.string().required("This option is required"),
  location: Yup.string().required("This option is required"),
  province: Yup.string().when("location", {
    is: "YES I AM BASED IN CANADA",
    then: Yup.string().required("This option is required")
  })
});
function MessageModal({ show, onHide, setShow }) {
  const [loading, setLoading] = useState(false);
  const [CURRENCY, SETCURRENCY] = useState("CA$");
  useEffect(() => {
    setShow(true);
  }, [show, setShow]);

  const btnRef = useRef();
  const saveClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            BlackForce Professional Upgrade Portal
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{
              type: `BLACKFORCE PROFESSIONAL ONE-TIME UPGRADE - $1,100`,
              location: "YES I AM BASED IN CANADA",
              province: ""
            }}
            validationSchema={UpgradeSchema}
            onSubmit={async values => {
              setLoading(true);
              if (values.location === "NO I LIVE ABROAD") {
                if (
                  values.type ===
                  "BLACKFORCE PROFESSIONAL ONE-TIME UPGRADE - $1,100"
                ) {
                  window.location.href =
                    "https://buy.stripe.com/dR6bJW5cAbvNd6U5kx";
                } else {
                  window.location.href =
                    "https://buy.stripe.com/14k4hu34s0R99UI00e";
                }
              } else {
                let paymentLink = getPaymentLink(values.type, values.province);
                window.location.href = paymentLink;
              }
              //   console.log(values);
              //   window.location.href = result.data.data?.payment_url;
              setLoading(false);
            }}
          >
            {({ handleSubmit, values, touched, errors }) => (
              <>
                <Form className="form form-label-right">
                  <div className="row">
                    <div className="col-lg-12 col-xl-12">
                      <h6 className="">Please select upgrade type</h6>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-12">
                      <Select
                        withFeedbackLabel={false}
                        name="type"
                        // label="Product type"
                      >
                        {[
                          `BLACKFORCE PROFESSIONAL ONE-TIME UPGRADE - $1,100`,
                          `BLACKFORCE PROFESSIONAL SPLIT-PAYMENT UPGRADE - $550`
                        ].map(manufacture => (
                          <option key={manufacture} value={manufacture}>
                            {manufacture}
                          </option>
                        ))}
                      </Select>
                      <span
                        className="form-text"
                        dangerouslySetInnerHTML={{
                          __html: getTypeDescription(values.type)
                        }}
                      ></span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-xl-12">
                      <h6 className="">Are you based in Canada?</h6>
                      <span className="form-text text-danger">
                        Please note that Canadian residents will be subject to
                        provincial taxes in accordance with official Canada HST
                        rates based on their selected province when completing
                        the payment.
                      </span>
                    </div>
                  </div>
                  <div className="form-group row mt-2">
                    <div className="col-lg-12">
                      <Select
                        withFeedbackLabel={false}
                        name="location"
                        // label="Product type"
                      >
                        {["YES I AM BASED IN CANADA", "NO I LIVE ABROAD"].map(
                          manufacture => (
                            <option key={manufacture} value={manufacture}>
                              {manufacture}
                            </option>
                          )
                        )}
                      </Select>
                      {/* <span
                        className="form-text"
                        dangerouslySetInnerHTML={{
                          __html: getTypeDescription(values.location)
                        }}
                      ></span> */}
                    </div>
                  </div>

                  {values.location === "YES I AM BASED IN CANADA" && (
                    <div className="form-group row">
                      <div className="col-lg-12">
                        <span>Select province</span>
                        <Select
                          withFeedbackLabel={false}
                          name="province"
                          // label="Product type"
                        >
                          <option value="">Select a province</option>
                          {[
                            "Alberta",
                            "British Columbia",
                            "Manitoba",
                            "New Brunswick",
                            "Newfoundland and Labrador",
                            "Northwest Territories",
                            "Nova Scotia",
                            "Nunavut",
                            "Ontario",
                            "Quebec",
                            "Prince Edward Island",
                            "Saskatchewan",
                            "Yukon"
                          ].map(manufacture => (
                            <option key={manufacture} value={manufacture}>
                              {manufacture}
                            </option>
                          ))}
                        </Select>
                        {/* <span
                          className="form-text"
                          dangerouslySetInnerHTML={{
                            __html: getTypeDescription(values.location)
                          }}
                        ></span> */}
                      </div>
                    </div>
                  )}
                  <div className="mt-n5 mb-5">
                    {touched.province && errors.province ? (
                      <span
                        className="mb-10"
                        style={{
                          color: "#F64E60",
                          marginTop: ".5rem",
                          fontSize: "0.9rem",
                          fontWeight: "400"
                        }}
                      >
                        {errors.province}
                      </span>
                    ) : null}
                  </div>

                  <div className="mt-4 text-center">
                    <div>
                      <h6>
                        Currency:{" "}
                        <b>
                          {
                            {
                              "YES I AM BASED IN CANADA": "CAD",
                              "NO I LIVE ABROAD": "USD"
                            }[values.location]
                          }
                        </b>
                      </h6>
                    </div>
                    <div>
                      <h6>
                        Gross Total:{" "}
                        <b>
                          {Number(
                            getAmountFigure("gross", values.type)
                          )?.toLocaleString("en-US", {
                            style: "currency",
                            currency: {
                              "YES I AM BASED IN CANADA": "CAD",
                              "NO I LIVE ABROAD": "USD"
                            }[values.location]
                          })}
                        </b>
                      </h6>
                    </div>
                    <div>
                      <h6>
                        Tax({taxAmount[values.province] || 0}%):{" "}
                        <b>
                          {Number(
                            getAmountFigure(
                              "tax",
                              values.location,
                              values.province,
                              values.type
                            )
                          )?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD"
                          })}
                        </b>
                      </h6>
                    </div>

                    <div>
                      <h6>
                        Total to Pay:{" "}
                        <b>
                          {Number(
                            getAmountFigure(
                              "total",
                              values.location,
                              values.province,
                              values.type
                            )
                          )?.toLocaleString("en-US", {
                            style: "currency",
                            currency: {
                              "YES I AM BASED IN CANADA": "CAD",
                              "NO I LIVE ABROAD": "USD"
                            }[values.location]
                          })}
                        </b>
                      </h6>
                    </div>
                    {/* )} */}
                    <div className=" text-center">
                      <img
                        onClick={() => handleSubmit()}
                        style={{
                          width: "15rem",
                          cursor: "pointer",
                          opacity: loading ? 0.5 : 1
                        }}
                        alt="Stripe"
                        src="/assets/card.jpeg"
                      ></img>
                      <br />
                      Pay with Stripe
                    </div>
                    <button
                      type="submit"
                      style={{ display: "none" }}
                      ref={btnRef}
                      onSubmit={() => handleSubmit()}
                    ></button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              disabled={loading}
              type="button"
              onClick={saveClick}
              className="btn btn-primary btn-elevate"
            >
              Pay now
            </button>
            <> </>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MessageModal;
