/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { updateProfileStatus } from "../../../app/modules/Profiles/_redux/profileActions";
import { toast } from "react-toastify";

export function DropdownMenu2({ id }) {
  const dispatch = useDispatch();
  const handleReferenceLetter = key => {
    Swal.fire({
      title: "Are you sure?",
      text: `This user will be able to generate a ${key} letter?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Submit"
    }).then(result => {
      if (result.isConfirmed) {
        dispatch(updateProfileStatus(id, key)).then(res => {
          if (res.data.status) {
            toast.success("Profile uploaded successfully!");
          } else {
            toast.error(
              "Something went wrong! Please try again or contact an Admin"
            );
          }
        });
        // saveUser(values, setStatus, setSubmitting);
        // Swal.fire("Deleted!", "Your file has been deleted.", "success");
      } else {
        // setSubmitting(false);
      }
    });
  };
  return (
    <>
      {/*begin::Navigation*/}
      <ul className="navi navi-hover">
        {/* <li className="navi-header pb-1">
                <span className="text-primary text-uppercase font-weight-bold font-size-sm">Add new:</span>
            </li> */}
        <li className="navi-item">
          <a href="#" className="navi-link">
            <span className="navi-icon">
              <i className="flaticon2-group"></i>
            </span>
            <span className="navi-text">Add to group</span>
          </a>
        </li>
        <li className="navi-item">
          <a href="#" className="navi-link">
            <span className="navi-icon">
              <i className="flaticon2-contract"></i>
            </span>
            <span
              onClick={() => handleReferenceLetter("Reference")}
              className="navi-text"
            >
              Generate Reference Letter
            </span>
          </a>
        </li>
        <li className="navi-item">
          <a href="#" className="navi-link">
            <span className="navi-icon">
              <i className="flaticon2-graph-1"></i>
            </span>
            <span
              onClick={() => handleReferenceLetter("Contract")}
              className="navi-text"
            >
              Generate Contract Letter
            </span>
          </a>
        </li>
        {/* <li className="navi-item">
          <a href="#" className="navi-link">
            <span className="navi-icon">
              <i className="flaticon2-rocket-1"></i>
            </span>
            <span className="navi-text">Post</span>
          </a>
        </li>
        <li className="navi-item">
          <a href="#" className="navi-link">
            <span className="navi-icon">
              <i className="flaticon2-writing"></i>
            </span>
            <span className="navi-text">File</span>
          </a>
        </li> */}
      </ul>
      {/*end::Navigation*/}
    </>
  );
}
