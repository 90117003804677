import React, { useState } from "react";
import MessageModal from "./Message";

function UpgradePage() {
  const [show, setShow] = useState(false);
  return (
    <>
      <MessageModal
        setShow={setShow}
        show={show}
        onHide={() => setShow(false)}
      />
    </>
  );
}

export default UpgradePage;
