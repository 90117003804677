import { createSlice } from "@reduxjs/toolkit";

const initialProfileState = {
  listLoading: false,
  actionsLoading: false,
  referenceLoading: false,
  totalCount: 0,
  entities: undefined,
  profileForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action",
  reference: "reference"
};

export const profileSlice = createSlice({
  name: "profile",
  initialState: initialProfileState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = false;
      } else {
        state.referenceLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else if (action.payload.callType === callTypes.action) {
        state.actionsLoading = true;
      } else {
        state.referenceLoading = true;
      }
    },
    // getProfileById
    profileFetched: (state, action) => {
      state.actionsLoading = false;
      state.profileForEdit = action.payload.profile;
      state.error = null;
    },
    referenceFetched: (state, action) => {
      state.referenceLoading = false;
      state.error = null;
    }
  }
});
