import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
import { OverviewCard } from "./OverviewCard";

function ProfileCard({ user, profile }) {
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <OverviewCard user={user} profile={profile} />
        </div>
      </div>
    </>
  );
}

export default ProfileCard;
