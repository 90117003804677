import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ProfileCard from "./ProfileCard";
import ReviewForm from "./ReviewForm1";
import { getProfile } from "./_redux/ProfileActions";
import { ModalProgressBar } from "../controls";
import NoSubscription from "./components/NoSubscription";

export function Demo1Dashboard({ user }) {
  const dispatch = useDispatch();
  useEffect(() => {
    // server call by queryParams
    dispatch(getProfile(user?.user_detail?.account_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const { profile, actionsLoading } = useSelector(
    ({ profile }) => ({
      profile: profile.profileForEdit,
      actionsLoading: profile.actionsLoading
    }),
    shallowEqual
  );

  return (
    <>
      {actionsLoading ? (
        <>
          <ModalProgressBar />
          <div className="card card-custom gutter-b">
            <div className="card-body"></div>
          </div>
        </>
      ) : (
        <>
          <ProfileCard user={user} profile={profile} />
          <ReviewForm profile={profile} user={user} />
        </>
      )}
    </>
  );
}
