import axios from "axios";

export const PROFILE_URL = process.env.REACT_APP_API_URL + "/profiles";

// CREATE =>  POST: add a new profile to the server
export function createProfile(profile) {
  return axios.post(PROFILE_URL, { profile });
}
export function approveProfile(values, id) {
  return axios.post(`${PROFILE_URL}/${id}/approve`, { values });
}

// READ
export function getAllProfile() {
  return axios.get(PROFILE_URL);
}

export function getProfileById(profileId) {
  return axios.get(`${PROFILE_URL}/${profileId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findProfile(queryParams) {
  return axios.get(`${PROFILE_URL}?query=${JSON.stringify(queryParams)}`);
}

// UPDATE => PUT: update the procuct on the server
export function updateProfile(profile) {
  return axios.put(`${PROFILE_URL}/${profile.id}`, { profile });
}

// UPDATE Status
export function updateProfileStatus(id, key) {
  return axios.post(`${PROFILE_URL}/status`, {
    id,
    key
  });
}

// DELETE => delete the profile from the server
export function deleteProfile(profileId) {
  return axios.delete(`${PROFILE_URL}/${profileId}`);
}

// DELETE Profile by ids
export function deleteProfiles(ids) {
  return axios.post(`${PROFILE_URL}/deleteMultiple`, { profiles: ids });
}

export function getManagers() {
  return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/users/managers`);
}

// export function fetchDashboard() {
//   return axios.get("http://localhost:3001/dashboard");
// }

export function fetchDashboard() {
  return axios.get(process.env.REACT_APP_API_ENDPOINT + "/dashboard");
}
