import { toast } from "react-toastify";
import { login_using_refresh_token } from "../app/modules/Auth/_redux/authCrud";
import * as auth from "../app/modules/Auth/_redux/authRedux";

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );
  axios.interceptors.response.use(
    response => {
      // response.data?.message && toast.success(response.data.message);
      return response;
    },
    async error => {
      if (error.response.status === 401) {
        store.dispatch(auth.actions.logout());
        throw error;
      } else if (error.response.status === 403) {
        store.dispatch(auth.actions.logout());
        error.response.data?.message &&
          error.response.data.message == "Expired Token" &&
          toast.error("Your session has expired!, please login.");
        throw error;
      } else if (
        error.response.status === 400 ||
        error.response.status === 500 ||
        error.response.status === 404
      ) {
        return {
          ...error.response
        };
      }
      return error;
    }
  );
}
