/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import SVG from "react-inlinesvg";
import { NavLink } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import { checkIsActive, toAbsoluteUrl } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const { role_level } = useSelector(
    state => state.auth.user.role_details,
    shallowEqual
  );
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-highlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/* begin::section */}
        {/* <li className='menu-section '>
					<h4 className='menu-text'>Application</h4>
					<i className='menu-icon flaticon-more-v2'></i>
				</li> */}
        {/* end:: section */}

        <>
          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                />
              </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive("/study-groups", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/study-groups">
              <span className="svg-icon menu-icon">
                <span className="material-icons">group</span>
              </span>
              <span className="menu-text">Study Groups</span>
            </NavLink>
          </li>

          {/* <li
            className={`menu-item ${getMenuItemActive(
              "/internship-groups",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/internship-groups">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
              </span>
              <span className="menu-text">Internship Groups</span>
            </NavLink>
          </li> */}
          <li
            className={`menu-item ${getMenuItemActive("/certificates", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/assignments">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")}
                />
              </span>
              <span className="menu-text">Assignments/Projects</span>
            </NavLink>
          </li>
          <li
            className={`menu-item ${getMenuItemActive("/certificates", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/certificates">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")}
                />
              </span>
              <span className="menu-text">Certifications</span>
            </NavLink>
          </li>

          {role_level <= 4 && (
            <>
              <li className="menu-section ">
                <h4 className="menu-text">Admin</h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
              <li
                className={`menu-item ${getMenuItemActive("/profiles", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/profiles">
                  <span className="svg-icon menu-icon">
                    <span className="material-icons">phone</span>
                  </span>
                  <span className="menu-text">All Profiles</span>
                </NavLink>
              </li>
              {/* <li
                className={`menu-item ${getMenuItemActive(
                  "/templates",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/templates">
                  <span className="svg-icon menu-icon">
                    <span className="material-icons">work</span>
                  </span>
                  <span className="menu-text">Templates</span>
                </NavLink>
              </li> */}
              {/* <li
                className={`menu-item ${getMenuItemActive("/groups", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/groups">
                  <span className="svg-icon menu-icon">
                    <span className="material-icons">group</span>
                  </span>
                  <span className="menu-text">Groups</span>
                </NavLink>
              </li> */}
              {/* <li
                className={`menu-item ${getMenuItemActive("/groups", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/groups">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Shopping/Box2.svg")}
                    />
                  </span>
                  <span className="menu-text">Settings</span>
                </NavLink>
              </li> */}
            </>
          )}
        </>
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
