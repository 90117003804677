import * as requestFromServer from "./profileCrud";
import { profilesSlice, callTypes } from "./profileSlice";

const { actions } = profilesSlice;

export const fetchProfiles = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findProfile(queryParams)
    .then(response => {
      if (response.data.status) {
        const { totalCount, entities } = response.data.data;
        dispatch(actions.profilesFetched({ totalCount, entities }));
      } else {
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find profiles";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchProfile = id => dispatch => {
  if (!id) {
    return dispatch(actions.profileFetched({ profileForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getProfileById(id)
    .then(response => {
      const profile = response.data.data;
      dispatch(actions.profileFetched({ profileForEdit: profile }));
    })
    .catch(error => {
      error.clientMessage = "Can't find profile";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteProfile = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteProfile(id)
    .then(response => {
      dispatch(actions.profileDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete profile";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createProfile = profileForCreation => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.createProfile(profileForCreation);
    if (response.data.status) {
      const profile = response.data.data;
      dispatch(actions.profileCreated({ profile }));
    }
    return response;
  } catch (error) {
    console.log(error);
    error.clientMessage = "Can't create profile";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};

export const updateProfile = profile => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.updateProfile(profile);
    if (response.data.status) {
      dispatch(actions.profileUpdated({ profile }));
    }
    return response;
  } catch (error) {
    error.clientMessage = "Can't update profile";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};

export const deleteProfiles = ids => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.deleteProfiles(ids);
    if (response.data.status) {
      dispatch(actions.profilesDeleted({ ids }));
    }
    return response;
  } catch (error) {
    error.clientMessage = "Can't delete profiles";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};

export const fetchDashboard = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .fetchDashboard()
    .then(response => {
      const dashboard = response.data.data;
      dispatch(actions.dashboardFetched({ dashboard }));
    })
    .catch(error => {
      error.clientMessage = "Can't find profile";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const updateProfileStatus = (id, key) => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.updateProfileStatus(id, key);
    if (response.data.status) {
      const profile = response.data.data;
      dispatch(actions.profileFetched({ profileForEdit: profile }));
    }
    return response;
  } catch (error) {
    console.log(error);
    error.clientMessage = "Can't update profile";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};
export const approveProfile = (profile, id) => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer.approveProfile(profile, id);
    if (response.data.status) {
      const profile = response.data.data;
      dispatch(actions.profileFetched({ profileForEdit: profile }));
    }
    return response;
  } catch (error) {
    console.log(error);
    error.clientMessage = "Can't update profile";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
    return error.response ? error.response : error;
  }
};
